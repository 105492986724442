import * as client_hooks from '../../../src/hooks.client.ts';


export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24'),
	() => import('./nodes/25'),
	() => import('./nodes/26'),
	() => import('./nodes/27'),
	() => import('./nodes/28'),
	() => import('./nodes/29'),
	() => import('./nodes/30'),
	() => import('./nodes/31'),
	() => import('./nodes/32'),
	() => import('./nodes/33'),
	() => import('./nodes/34'),
	() => import('./nodes/35'),
	() => import('./nodes/36'),
	() => import('./nodes/37'),
	() => import('./nodes/38'),
	() => import('./nodes/39'),
	() => import('./nodes/40'),
	() => import('./nodes/41'),
	() => import('./nodes/42'),
	() => import('./nodes/43'),
	() => import('./nodes/44'),
	() => import('./nodes/45'),
	() => import('./nodes/46'),
	() => import('./nodes/47'),
	() => import('./nodes/48'),
	() => import('./nodes/49'),
	() => import('./nodes/50'),
	() => import('./nodes/51'),
	() => import('./nodes/52'),
	() => import('./nodes/53'),
	() => import('./nodes/54'),
	() => import('./nodes/55'),
	() => import('./nodes/56'),
	() => import('./nodes/57'),
	() => import('./nodes/58'),
	() => import('./nodes/59'),
	() => import('./nodes/60'),
	() => import('./nodes/61'),
	() => import('./nodes/62'),
	() => import('./nodes/63'),
	() => import('./nodes/64'),
	() => import('./nodes/65'),
	() => import('./nodes/66'),
	() => import('./nodes/67'),
	() => import('./nodes/68'),
	() => import('./nodes/69'),
	() => import('./nodes/70'),
	() => import('./nodes/71'),
	() => import('./nodes/72'),
	() => import('./nodes/73'),
	() => import('./nodes/74'),
	() => import('./nodes/75'),
	() => import('./nodes/76'),
	() => import('./nodes/77'),
	() => import('./nodes/78'),
	() => import('./nodes/79'),
	() => import('./nodes/80'),
	() => import('./nodes/81'),
	() => import('./nodes/82'),
	() => import('./nodes/83'),
	() => import('./nodes/84'),
	() => import('./nodes/85'),
	() => import('./nodes/86'),
	() => import('./nodes/87'),
	() => import('./nodes/88'),
	() => import('./nodes/89'),
	() => import('./nodes/90'),
	() => import('./nodes/91'),
	() => import('./nodes/92'),
	() => import('./nodes/93'),
	() => import('./nodes/94'),
	() => import('./nodes/95'),
	() => import('./nodes/96'),
	() => import('./nodes/97'),
	() => import('./nodes/98'),
	() => import('./nodes/99'),
	() => import('./nodes/100'),
	() => import('./nodes/101'),
	() => import('./nodes/102'),
	() => import('./nodes/103'),
	() => import('./nodes/104'),
	() => import('./nodes/105'),
	() => import('./nodes/106'),
	() => import('./nodes/107'),
	() => import('./nodes/108'),
	() => import('./nodes/109'),
	() => import('./nodes/110'),
	() => import('./nodes/111'),
	() => import('./nodes/112'),
	() => import('./nodes/113'),
	() => import('./nodes/114'),
	() => import('./nodes/115'),
	() => import('./nodes/116'),
	() => import('./nodes/117'),
	() => import('./nodes/118'),
	() => import('./nodes/119'),
	() => import('./nodes/120'),
	() => import('./nodes/121'),
	() => import('./nodes/122'),
	() => import('./nodes/123'),
	() => import('./nodes/124'),
	() => import('./nodes/125'),
	() => import('./nodes/126'),
	() => import('./nodes/127'),
	() => import('./nodes/128'),
	() => import('./nodes/129'),
	() => import('./nodes/130'),
	() => import('./nodes/131'),
	() => import('./nodes/132'),
	() => import('./nodes/133'),
	() => import('./nodes/134'),
	() => import('./nodes/135'),
	() => import('./nodes/136'),
	() => import('./nodes/137'),
	() => import('./nodes/138'),
	() => import('./nodes/139'),
	() => import('./nodes/140'),
	() => import('./nodes/141'),
	() => import('./nodes/142'),
	() => import('./nodes/143'),
	() => import('./nodes/144'),
	() => import('./nodes/145'),
	() => import('./nodes/146'),
	() => import('./nodes/147'),
	() => import('./nodes/148'),
	() => import('./nodes/149'),
	() => import('./nodes/150'),
	() => import('./nodes/151'),
	() => import('./nodes/152'),
	() => import('./nodes/153'),
	() => import('./nodes/154'),
	() => import('./nodes/155'),
	() => import('./nodes/156'),
	() => import('./nodes/157'),
	() => import('./nodes/158'),
	() => import('./nodes/159'),
	() => import('./nodes/160'),
	() => import('./nodes/161'),
	() => import('./nodes/162'),
	() => import('./nodes/163'),
	() => import('./nodes/164'),
	() => import('./nodes/165'),
	() => import('./nodes/166'),
	() => import('./nodes/167'),
	() => import('./nodes/168'),
	() => import('./nodes/169'),
	() => import('./nodes/170')
];

export const server_loads = [0,8,7,9,13,16,18,19];

export const dictionary = {
		"/(webflow-site)": [163,[21],[22]],
		"/(webflow-site)/academy": [165,[21],[22]],
		"/(webflow-site)/academy/[slug]": [166,[21],[22]],
		"/(migrated)/app": [~39,[5]],
		"/(migrated)/app/applications/new": [~57,[6]],
		"/(migrated)/app/applications/one/[referenceCode]/aml": [~58,[6,8]],
		"/(migrated)/app/applications/one/[referenceCode]/documents": [59,[6,8]],
		"/(migrated)/app/applications/one/[referenceCode]/overview": [~60,[6,8]],
		"/(migrated)/app/applications/one/[referenceCode]/proof-of-identity": [~61,[6,8]],
		"/(migrated)/app/applications/one/[referenceCode]/submitted": [~63,[6,8]],
		"/(migrated)/app/applications/one/[referenceCode]/submit": [~62,[6,8]],
		"/(migrated)/app/applications/one/[referenceCode]/summary": [~64,[6,8]],
		"/(migrated)/app/applications/one/[referenceCode]/valuation": [~65,[6,8]],
		"/(migrated)/app/applications/[referenceCode]/additional-information": [~40,[6,7]],
		"/(migrated)/app/applications/[referenceCode]/aml": [~41,[6,7]],
		"/(migrated)/app/applications/[referenceCode]/documents": [42,[6,7]],
		"/(migrated)/app/applications/[referenceCode]/home-improvements": [~43,[6,7]],
		"/(migrated)/app/applications/[referenceCode]/management-company": [~44,[6,7]],
		"/(migrated)/app/applications/[referenceCode]/mortgage": [~45,[6,7]],
		"/(migrated)/app/applications/[referenceCode]/overview": [~46,[6,7]],
		"/(migrated)/app/applications/[referenceCode]/ownership": [~47,[6,7]],
		"/(migrated)/app/applications/[referenceCode]/personal": [~48,[6,7]],
		"/(migrated)/app/applications/[referenceCode]/proof-of-identity": [~49,[6,7]],
		"/(migrated)/app/applications/[referenceCode]/property": [~50,[6,7]],
		"/(migrated)/app/applications/[referenceCode]/solicitor": [~51,[6,7]],
		"/(migrated)/app/applications/[referenceCode]/submitted": [~53,[6,7]],
		"/(migrated)/app/applications/[referenceCode]/submit": [~52,[6,7]],
		"/(migrated)/app/applications/[referenceCode]/summary": [~54,[6,7]],
		"/(migrated)/app/applications/[referenceCode]/valuation-request": [~56,[6,7]],
		"/(migrated)/app/applications/[referenceCode]/valuation": [~55,[6,7]],
		"/(migrated)/app/calculator": [~66],
		"/(migrated)/app/guide": [~67,[9]],
		"/(migrated)/app/guide/affordability": [68,[9]],
		"/(migrated)/app/guide/apply": [69,[9]],
		"/(migrated)/app/guide/hpi": [70,[9]],
		"/(migrated)/app/guide/mortgage": [71,[9]],
		"/(migrated)/app/guide/valuation": [72,[9]],
		"/(migrated)/app/learn": [73,[5]],
		"/(migrated)/app/learn/(learn)/article/[slug]": [74,[5,10]],
		"/(migrated)/app/learn/(learn)/category/[slug]": [75,[5,10]],
		"/(migrated)/app/learn/(learn)/search": [~76,[5,10]],
		"/(migrated)/app/learn/(learn)/tag/[slug]": [77,[5,10]],
		"/(migrated)/app/logout": [~78,[5]],
		"/(migrated)/app/plan": [~79,[5]],
		"/(migrated)/app/profile": [~80,[5,11]],
		"/(migrated)/app/profile/financials": [~81,[5,11]],
		"/(migrated)/app/profile/marketing-preferences": [~82,[5,11]],
		"/(migrated)/app/profile/property": [~83,[5,11]],
		"/(migrated)/app/property/register": [~84,[12]],
		"/(migrated)/app/property/register/intentions": [~85,[12]],
		"/(migrated)/app/property/register/property-details": [~86,[12]],
		"/(migrated)/app/refer": [~87,[5]],
		"/(migrated)/app/signup": [88,[5]],
		"/(migrated)/app/staircasing": [~89,[5]],
		"/(migrated)/app/stamp-duty": [~90],
		"/(migrated)/app/valuation": [~91],
		"/(migrated)/app/you": [~92],
		"/(migrated)/(auth)/auth/confirm": [~23,[2]],
		"/(migrated)/calculator": [~93,[13]],
		"/(migrated)/calculator/financials": [94,[13]],
		"/(migrated)/calculator/property": [95,[13]],
		"/(migrated)/calculator/remortgage": [~96,[13]],
		"/(migrated)/calculator/results": [~97,[13]],
		"/(webflow-site)/category/[slug]": [167,[21],[22]],
		"/(migrated)/contact": [~98,[14]],
		"/(migrated)/contact/thank-you": [99,[14]],
		"/(webflow-site)/housing-associations": [168,[21],[22]],
		"/(migrated)/instruct/broker": [~100,[15]],
		"/(migrated)/instruct/broker/thank-you": [101,[15]],
		"/(migrated)/instruct/rics": [~102,[15]],
		"/(migrated)/instruct/rics/thank-you": [103,[15]],
		"/(migrated)/is-shared-ownership-right-for-me/(form)": [~104,[16,17]],
		"/(migrated)/is-shared-ownership-right-for-me/(form)/dream-home": [~105,[16,17]],
		"/(migrated)/is-shared-ownership-right-for-me/results": [106,[16]],
		"/(migrated)/(auth)/login": [~24,[2]],
		"/(migrated)/(auth)/login/forgot": [~25,[2]],
		"/(migrated)/(auth)/login/password": [~26,[2]],
		"/(migrated)/(auth)/login/reset": [~27,[2]],
		"/(migrated)/(auth)/login/verify": [28,[2]],
		"/(migrated)/(auth)/login/wrong-device": [29,[2]],
		"/(migrated)/maintenance": [107],
		"/(migrated)/(legal)/privacy-policy": [37,[4]],
		"/(migrated)/resales": [~108,[18]],
		"/(migrated)/resales/instant": [~109,[18]],
		"/(migrated)/resales/instant/thank-you": [110,[18]],
		"/(webflow-site)/search": [~169,[21],[22]],
		"/(migrated)/(auth)/signup/application-gateway": [~30,[2]],
		"/(migrated)/signup/calculator": [111,[19]],
		"/(migrated)/signup/calculator/interest/knowledge-level": [112,[19]],
		"/(migrated)/signup/calculator/interest/why": [113,[19]],
		"/(migrated)/signup/calculator/next-steps": [114,[19]],
		"/(migrated)/signup/calculator/plan/reason-for-waiting": [115,[19]],
		"/(migrated)/signup/calculator/plan/staircasing-process": [116,[19]],
		"/(migrated)/signup/calculator/plan/waiting-for-event-details": [118,[19]],
		"/(migrated)/signup/calculator/plan/waiting-for-event": [117,[19]],
		"/(migrated)/signup/calculator/property-details/address": [~119,[19]],
		"/(migrated)/signup/calculator/property-details/confirm": [~120,[19]],
		"/(migrated)/signup/calculator/property-details/provider": [~121,[19]],
		"/(migrated)/signup/calculator/property-details/purchase-info": [122,[19]],
		"/(migrated)/signup/calculator/property-details/unit-info": [123,[19]],
		"/(migrated)/signup/calculator/sign-up/confirm-email": [124,[19]],
		"/(migrated)/signup/calculator/sign-up/details": [~125,[19]],
		"/(migrated)/(auth)/signup/from-website": [~31,[2]],
		"/(migrated)/(auth)/signup/invite": [~32,[2]],
		"/(migrated)/signup/learning": [126,[19]],
		"/(migrated)/signup/learning/email-address": [~127,[19]],
		"/(migrated)/signup/learning/info/finance": [128,[19]],
		"/(migrated)/signup/learning/info/mortgage": [129,[19]],
		"/(migrated)/signup/learning/info/property-value": [130,[19]],
		"/(migrated)/signup/learning/info/selling": [131,[19]],
		"/(migrated)/signup/learning/info/staircasing": [132,[19]],
		"/(migrated)/signup/learning/info/when": [133,[19]],
		"/(migrated)/signup/learning/next-steps": [134,[19]],
		"/(migrated)/signup/learning/ownership": [135,[19]],
		"/(migrated)/signup/learning/plan/when": [136,[19]],
		"/(migrated)/signup/learning/plan/why": [137,[19]],
		"/(migrated)/signup/learning/property-details/address": [~138,[19]],
		"/(migrated)/signup/learning/property-details/confirm": [~139,[19]],
		"/(migrated)/signup/learning/property-details/provider": [~140,[19]],
		"/(migrated)/signup/learning/property-details/purchase-info": [141,[19]],
		"/(migrated)/signup/learning/property-details/unit-info": [142,[19]],
		"/(migrated)/signup/learning/sign-up/confirm-email": [143,[19]],
		"/(migrated)/signup/learning/sign-up/details": [~144,[19]],
		"/(migrated)/signup/staircasing": [~145,[19]],
		"/(migrated)/signup/staircasing/interest/knowledge-level": [146,[19]],
		"/(migrated)/signup/staircasing/interest/why": [147,[19]],
		"/(migrated)/signup/staircasing/plan/affordability": [~148,[19]],
		"/(migrated)/signup/staircasing/plan/reason-for-waiting": [149,[19]],
		"/(migrated)/signup/staircasing/plan/staircase-now": [150,[19]],
		"/(migrated)/signup/staircasing/plan/waiting-for-event-details": [152,[19]],
		"/(migrated)/signup/staircasing/plan/waiting-for-event": [151,[19]],
		"/(migrated)/signup/staircasing/property-details/address": [~153,[19]],
		"/(migrated)/signup/staircasing/property-details/confirm": [~154,[19]],
		"/(migrated)/signup/staircasing/property-details/provider": [~155,[19]],
		"/(migrated)/signup/staircasing/property-details/purchase-info": [156,[19]],
		"/(migrated)/signup/staircasing/property-details/unit-info": [157,[19]],
		"/(migrated)/signup/staircasing/sign-up/confirm-email": [158,[19]],
		"/(migrated)/signup/staircasing/sign-up/details": [~159,[19]],
		"/(migrated)/signup/start": [160,[19]],
		"/(migrated)/(auth)/signup/verify": [~33,[2]],
		"/(migrated)/(calculators)/stamp-duty": [~34,[3]],
		"/(migrated)/(calculators)/stamp-duty/results": [~35,[3]],
		"/(migrated)/(calculators)/stamp-duty/signup": [~36,[3]],
		"/(webflow-site)/tag/[slug]": [170,[21],[22]],
		"/(migrated)/(legal)/terms-and-conditions": [38,[4]],
		"/(migrated)/unsubscribe": [~161,[20]],
		"/(migrated)/unsubscribe/confirmed": [162,[20]],
		"/(webflow-site)/[providerName]": [~164,[21],[22]]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),

	reroute: (() => {})
};

export { default as root } from '../root.svelte';